



























































import Vue from 'vue';
import Component from 'vue-class-component';
import AuthContainer from '@/components/AuthContainer.vue';
import { ErrorAPI } from '@/api';

@Component({
  components: {
    AuthContainer
  },
})
export default class Login extends Vue {
  private username: string = '';
  private password: string = '';
  private errors = new ErrorAPI();
  private isLoading: boolean = false;

  private login(e) {
    this.isLoading = true;
    this.errors.clear();

    if (!this.validate()) {
      return false;
    }

    this.$store.dispatch('auth/login', { username: this.username, password: this.password })
      .then(() => this.$router.push('/dashboard'))
      .catch((err: any) => {
        if (err.response === undefined) {
          this.errors.push('default', [this.$t('SrvPoblemMsg').toString()]);
          return;
        }

        if (err.response?.status === 403 || err.response?.status === 401) {
          this.errors.push('default', err.response.data);
        } else {
          this.errors.push('default', [err.response?.data?.message]);
        }
      })
      .finally(() => this.isLoading = false);
  }

  private validate() {
    const form = this.$refs['vuejs-form-data'] as HTMLFormElement;
    if (form.checkValidity && !form.checkValidity()) {
      const elements = [...form.elements] as HTMLElement[];
      elements.forEach((e) => e.focus());
      // form.reportValidity();
      return false;
    }

    return true;
  }
}
